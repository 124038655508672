<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Postulaciones</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Tif</li>
                  <li class="breadcrumb-item active">General</li>
                  <li class="breadcrumb-item active">Operaciones</li>
                  <li class="breadcrumb-item active">Postulaciones</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2">
                <div class="row">
                  <div class="col-md-10"></div>
                  <div class="col-md-12">
                    <div class="btn-group float-right">
                      <button
                        type="button"
                        class="btn btn-success"
                        data-toggle="modal"
                        data-target="#modal_form_postulaciones_export"
                        v-if="$store.getters.can('tif.postulaciones.export')"
                      >
                        <i class="far fa-file-excel"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body p-0">
                <table
                  id="postulaciones"
                  class="table table-bordered table-striped table-hover table-sm"
                >
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>
                        Nombre
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filters.nombre"
                          @input="getIndex()"
                        />
                      </th>
                      <th>
                        Bloque
                        <v-select
                          :class="
                            $store.getters.getDarkMode ? 'dark-vselect' : ''
                          "
                          v-model="filters.bloque"
                          placeholder="Nombre"
                          label="nombre"
                          :options="listasForms.bloques"
                          @search="buscarBloques"
                          @input="getIndex()"
                        ></v-select>
                      </th>
                      <th class="text-center">
                        Transportadora
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filters.empresa"
                          @input="getIndex()"
                        />
                      </th>
                      <th>
                        Fecha Inicio
                        <input
                          type="date"
                          class="form-control form-control-sm"
                          v-model="filters.fecha_inicial"
                          @change="getIndex()"
                        />
                      </th>
                      <th>
                        Fecha Fin
                        <input
                          type="date"
                          class="form-control form-control-sm"
                          v-model="filters.fecha_final"
                          @change="getIndex()"
                        />
                      </th>
                      <th>Observacion</th>
                      <th>Solicitados</th>
                      <th>N postulados</th>
                      <th class="text-center">Estado</th>
                      <th>Acción</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="sol in solicitudes.data" :key="sol.id">
                      <td>
                        {{ sol.id }}
                      </td>
                      <td>{{ sol.nombre }}</td>
                      <td>{{ sol.bloque.nombre }}</td>
                      <td>{{ sol.empresa.razon_social }}</td>
                      <td>{{ sol.fecha_ini }}</td>
                      <td>{{ sol.fecha_fin }}</td>
                      <td>{{ sol.observacion }}</td>
                      <td class="text-center">
                        <span class="badge badge-info">{{
                          sol.vehi_solicitados
                        }}</span>
                      </td>
                      <td class="text-center">
                        <span
                          class="badge"
                          :class="
                            sol.tif_det_postulaciones.length == 0
                              ? 'badge-danger'
                              : sol.tif_det_postulaciones.length <
                                sol.tif_det_solicitudes_vehiculos[0].cantidad
                              ? 'badge-warning'
                              : 'badge-success'
                          "
                          >{{ sol.tif_det_postulaciones.length }}</span
                        >
                      </td>
                      <td
                        v-if="
                          sol.tif_det_postulaciones.length <
                          sol.tif_det_solicitudes_vehiculos[0].cantidad
                        "
                      >
                        No Postulado
                      </td>
                      <td v-else>Postulado</td>

                      <td style="width: 40px" class="text-center">
                        <div class="btn-group float-center">
                          <button
                            type="button"
                            v-if="
                              $store.getters.can('tif.postulaciones.create')
                            "
                            @click="asignarVehiculos(sol)"
                            class="btn btn-sm bg-navy"
                          >
                            <i class="fas fa-calendar-plus"></i>
                          </button>
                          <button
                            type="button"
                            v-if="$store.getters.can('tif.postulaciones.show')"
                            @click="visualizarVehiculos(sol)"
                            class="btn btn-sm bg-info"
                          >
                            <i class="fas fa-eye"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="card-footer">
                <div class="float-left" v-if="solicitudes.total">
                  <p>
                    Mostrando del <b>{{ solicitudes.from }}</b> al
                    <b>{{ solicitudes.to }}</b> de un total:
                    <b>{{ solicitudes.total }}</b> Registros
                  </p>
                </div>
                <div class="float-left" v-else>
                  <p>
                    <span class="badge badge-danger">
                      No hay registros para mostrar
                      {{ solicitudes.total }}
                    </span>
                  </p>
                </div>
                <pagination
                  :data="solicitudes"
                  @pagination-change-page="getIndex"
                  :limit="5"
                  align="right"
                ></pagination>
              </div>
            </div>
          </div>
          <TifPostulacionesExport ref="TifPostulacionesExport" />
        </section>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import pagination from "laravel-vue-pagination";
import Loading from "../../../../components/Loading";
import TifPostulacionesExport from "../postulaciones/TifPostulacionesExport";
// import { mapActions, mapState } from "vuex";
import vSelect from "vue-select";
import moment from "moment";

export default {
  name: "TifPostulacionIndex",
  components: {
    Loading,
    pagination,
    TifPostulacionesExport,
    vSelect,
  },
  data() {
    return {
      cargando: false,
      distancia: null,
      postulaciones: [],
      filters: {
        nombre: null,
        bloque: null,
        empresa: null,
        estado: null,
        fecha_inicial: null,
        fecha_final: null,
      },
      listasForms: {
        bloques: [],
        empresas: [],
        estados: [],
      },
      nominaciones: [],
      bloques: [],
      solicitudes: {},
    };
  },

  methods: {
    getIndex(page = 1) {
      this.cargando = true;
      if (this.filters.bloque) {
        this.filters.bloque_id = this.filters.bloque.id;
      }
      if (this.filters.empresa) {
        this.filters.empresa_id = this.filters.empresa.id;
      }
      axios
        .get("/api/tif/postulaciones/solicitudes?page=" + page, {
          params: this.filters,
        })
        .then((response) => {
          this.solicitudes = response.data;          
          this.cargando = false;

          for (let i = 0; i < this.solicitudes.data.length; i++) {
            let fechaIni = moment(this.solicitudes.data[i].fecha_ini);
            let fechaFin = moment(this.solicitudes.data[i].fecha_fin);
            let diferencia = fechaFin.diff(fechaIni, "days") + 1;

            this.solicitudes.data[i].diff = diferencia;
            let vehiSoli =
              diferencia *
              this.solicitudes.data[i].tif_det_solicitudes_vehiculos[0]
                .cantidad;
            this.solicitudes.data[i].vehi_solicitados = vehiSoli;
          }
        });
    },

    buscarBloques(search, loading) {
      if (search != "") {
        let me = this;
        loading(true);
        var url = "api/admin/bloques/lista?bloque=" + search;
        axios
          .get(url)
          .then(function (response) {
            let respuesta = response.data;
            me.listasForms.bloques = respuesta;
            loading(false);
          })
          .catch(function (error) {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    buscarEmpresa(search, loading) {
      if (search != "") {
        let me = this;
        loading(true);
        var url = "/admin/empresas/lista?empresa=" + search;
        axios
          .get(url)
          .then(function (response) {
            let respuesta = response.data;
            me.listasForms.empresas = respuesta;
            loading(false);
          })
          .catch(function (error) {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    getEstados() {
      axios.get("/api/lista/106").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    asignarVehiculos(sol) {
      return this.$router.push({
        name: "/Tif/PostulacionesForm",
        params: {
          solicitud: sol,
          act: 1,
        },
      });
    },

    visualizarVehiculos(sol) {
      return this.$router.push({
        name: "/Tif/PostulacionesForm",
        params: {
          solicitud: sol,
          act: 2,
        },
      });
    },
  },
  mounted() {
    this.getIndex();
    this.getEstados();
    // this.getTipoOperaciones();
  },
};
</script>
